import React, { memo } from 'react';
import { Button, Flex, Input, Label } from 'theme-ui';
import { styles } from './FilterControl.styles';

export const FilterControls = memo(
  ({
    totalValue,
    rankValue,
    onTotalChange,
    onRankChange,
    onApply,
    onClear,
  }: {
    totalValue: string;
    rankValue: string;
    onTotalChange: (value: string) => void;
    onRankChange: (value: string) => void;
    onApply: () => void;
    onClear: () => void;
  }) => {
    const handleKeyPress = (e: React.KeyboardEvent) => {
      if (e.key === 'Enter') {
        onApply();
      }
    };

    return (
      <Flex sx={styles.container}>
        <Label sx={styles.label}>
          Filter by total (e.g. 0.20)
          <Input
            type='number'
            step='0.01'
            value={totalValue}
            onChange={(e) => onTotalChange(e.target.value)}
            onKeyPress={handleKeyPress}
            sx={styles.input}
          />
        </Label>
        <Label sx={styles.label}>
          Filter by rank (e.g. 10)
          <Input type='number' value={rankValue} onChange={(e) => onRankChange(e.target.value)} onKeyPress={handleKeyPress} sx={styles.input} />
        </Label>
        <Button sx={styles.applyButton} onClick={onApply}>
          Apply
        </Button>
        <Button sx={styles.clearButton} onClick={onClear}>
          Clear
        </Button>
      </Flex>
    );
  }
);
FilterControls.displayName = 'FilterControls';
