import { useCallback, useMemo, useState } from 'react';
import { HistoryRanking } from './useHistoryRanking';

export function useTrendFilter(data: HistoryRanking[] | undefined) {
  const [totalInputValue, setTotalInputValue] = useState<string>('');
  const [rankInputValue, setRankInputValue] = useState<string>('');

  const [totalFilter, setTotalFilter] = useState<number>();
  const [rankFilter, setRankFilter] = useState<number>();

  const applyFilters = useCallback(() => {
    setTotalFilter(totalInputValue ? Number(totalInputValue) : undefined);
    setRankFilter(rankInputValue ? Number(rankInputValue) : undefined);
  }, [totalInputValue, rankInputValue]);

  const clearFilters = useCallback(() => {
    setTotalInputValue('');
    setRankInputValue('');
    setTotalFilter(undefined);
    setRankFilter(undefined);
  }, []);

  const filtered = useMemo(() => {
    if (!data) return [];

    return data.filter((item) => {
      const totalFilterPass =
        totalFilter === undefined ? true : item.current.totalTrend !== undefined && Math.abs(item.current.totalTrend) >= totalFilter;

      const rankFilterPass = rankFilter === undefined ? true : item.current.rankTrend !== undefined && Math.abs(item.current.rankTrend) >= rankFilter;

      return totalFilterPass && rankFilterPass;
    });
  }, [data, totalFilter, rankFilter]);

  return {
    data: filtered,
    totalInputValue,
    rankInputValue,
    setTotalInputValue,
    setRankInputValue,
    applyFilters,
    clearFilters,
  };
}
