import { themeConfig } from '@themed';
import { SxStyleProp } from 'theme-ui';

const buttonStyles = {
  variant: 'buttons.pure',
  color: 'primary',
  textAlign: 'center',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  border: `1px solid ${themeConfig.colors.text}`,
  px: 6,
  py: 2,
  borderRadius: 2,
  mt: '12px',
};

export const styles = {
  container: {
    gap: 2,
    marginBottom: 4,
    width: 'max-content !important',
    alignItems: 'center',
  } as SxStyleProp,

  label: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: 'xxxs',
    color: 'grey.1',
  } as SxStyleProp,

  input: {
    fontSize: 'xxs',
    borderRadius: '4px',
    maxWidth: '200px',
    padding: 2,
    border: `1px solid ${themeConfig.colors.mutedDark}`,
    '&:focus': {
      outline: 'none',
    },
    mr: 4,
  },

  applyButton: {
    ...buttonStyles,
    bg: 'text',
    color: 'white',
  },
  clearButton: {
    ...buttonStyles,
    bg: 'white',
    color: 'text',
  },
};
