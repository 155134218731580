import styled from '@emotion/styled';
import { useLocale } from '@modules';
import { Box } from '@themed';
import { FsType, FsTypeBase } from '@types';
import React, { JSXElementConstructor, ReactElement } from 'react';
import { HistoryRanking } from '../hooks';
import { useCountryFilter, useIsCountryRanking } from '../hooks/useCountryFilter';
import { useDimensionFilter } from '../hooks/useDimensionFilter';
import { useTrendFilter } from '../hooks/useTrendFilter';
import { useDimensionKey } from '../store/DimensionStore';
import { FilterControls } from './FilterControl';
import { styles } from './RankingTable.styles';
import { CountryRankingTableRow, RankingTableRow } from './TableBody';
import { CountryRankingTableHeader, RankingTableHeader } from './TableHeader';

const Table = styled.table({
  borderCollapse: 'collapse',
});

type RankingTableDataProps = {
  data: HistoryRanking[];
  fsType: FsTypeBase<FsType>;
  date: string;
  compareDate: string;
};

type RankingTableProps = RankingTableDataProps & {
  tableHeader: (props: any) => ReactElement<any, string | JSXElementConstructor<any>> | null;
  tableRow: (props: any) => ReactElement<any, string | JSXElementConstructor<any>> | null;
};

function RankingTable({ data, fsType, date, compareDate, tableHeader: TableHeader, tableRow: TableRow }: RankingTableProps) {
  const { data: ranking, totalInputValue, rankInputValue, setTotalInputValue, setRankInputValue, applyFilters, clearFilters } = useTrendFilter(data);
  const locale = useLocale();

  return (
    <>
      <FilterControls
        totalValue={totalInputValue}
        rankValue={rankInputValue}
        onTotalChange={setTotalInputValue}
        onRankChange={setRankInputValue}
        onApply={applyFilters}
        onClear={clearFilters}
      />
      {ranking && (
        <Box id='chart' sx={styles.table}>
          <Table>
            <TableHeader fsType={fsType} date={date} compareDate={compareDate} />
            <tbody>
              {ranking.map((item) => (
                <TableRow
                  key={item.current.id}
                  ranking={item}
                  linkProps={{
                    to: `/${locale}/charts?fsType=${fsType.text}&date=${date}&compareDate=${compareDate}&institutionId=${item.current.id}`,
                    sx: { all: 'unset' },
                  }}
                />
              ))}
            </tbody>
          </Table>
        </Box>
      )}
    </>
  );
}

function CountryRankingTable({ data, ...props }: RankingTableDataProps) {
  const countryRanking = useCountryFilter(data);
  return <RankingTable {...props} data={countryRanking} tableHeader={CountryRankingTableHeader} tableRow={CountryRankingTableRow} />;
}

function DimensionRankingTable({ data, ...props }: RankingTableDataProps) {
  const dimensionRanking = useDimensionFilter(data);
  return <RankingTable {...props} data={dimensionRanking} tableHeader={RankingTableHeader} tableRow={RankingTableRow} />;
}

export const HistoryRankingTable = (props: RankingTableDataProps) => {
  const isCountryRank = useIsCountryRanking();
  const [rankedDimension] = useDimensionKey();

  if (rankedDimension !== null) {
    return <DimensionRankingTable {...props} />;
  }

  if (isCountryRank) {
    return <CountryRankingTable {...props} />;
  }

  return <RankingTable {...props} tableHeader={RankingTableHeader} tableRow={RankingTableRow} />;
};
