import { graphqlRequest, InstitutionQueryResult, useLocale } from '@modules';
import { CountryFromApi, Dimension, Image } from '@types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { getHistoryRankingQuery } from '../utils/graphql';

export type Ranking = {
  id: number;
  name: string;
  rank: number;
  countryRank: number;
  total: number;
  logo: Image | undefined;
  country?: CountryFromApi & { name?: string };
  rankTrend?: number;
  totalTrend?: number;
  countryRankTrend?: number;
  dimensions?: Dimension[];
};

export type HistoryRanking = {
  current: Ranking;
  compared: Ranking;
};

export function useHistoryRankingData(fsType?: string, date?: string, compareDate?: string) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<InstitutionQueryResult | null>(null);
  const locale = useLocale();

  const fetchData = useCallback(async () => {
    if (!fsType || !date || !compareDate) {
      return;
    }
    setIsLoading(true);
    const query = getHistoryRankingQuery(fsType, date, compareDate);

    try {
      const response = await graphqlRequest<InstitutionQueryResult>(query);
      setData(response);
    } catch (error) {
      console.error(error);
      setData(null);
    } finally {
      setIsLoading(false);
    }
  }, [fsType, date, compareDate]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const ranking: HistoryRanking[] = useMemo(
    () =>
      data?.currentPeriod[0]?.institutions
        ?.reduce<HistoryRanking[]>((acc, institution) => {
          if (!institution.country?.countryCode) return acc;

          const comparedInstitution = data?.comparePeriod[0]?.institutions?.find(
            (i) => i.name === institution.name && i.country.countryCode === institution.country.countryCode
          );

          const historyRanking: HistoryRanking = {
            current: {
              id: institution.id,
              name: institution.name,
              rank: institution.rank,
              countryRank: institution.countryRank,
              total: institution.total,
              logo: institution.logo,
              country: {
                ...institution.country,
                name: data?.translations.find((t) => t.key === institution.country.countryCode)?.[locale],
              },
              dimensions: institution.dimensions,
              totalTrend: comparedInstitution?.total ? institution.total - comparedInstitution.total : undefined,
              rankTrend: comparedInstitution?.rank ? comparedInstitution.rank - institution.rank : undefined,
              countryRankTrend: comparedInstitution?.countryRank ? comparedInstitution.countryRank - institution.countryRank : undefined,
            },
            compared: {
              id: comparedInstitution?.id ?? 0,
              name: comparedInstitution?.name ?? '',
              rank: comparedInstitution?.rank ?? 0,
              countryRank: comparedInstitution?.countryRank ?? 0,
              total: comparedInstitution?.total ?? 0,
              logo: comparedInstitution?.logo,
              country: comparedInstitution?.country && {
                ...comparedInstitution.country,
                name: data?.translations.find((t) => t.key === comparedInstitution.country.countryCode)?.[locale],
              },
              dimensions: comparedInstitution?.dimensions,
            },
          };

          return [...acc, historyRanking];
        }, [])
        .sort((a, b) => a.current.rank - b.current.rank) ?? [],
    [data, locale]
  );

  return { data: ranking, isLoading };
}
