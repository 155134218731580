import { ALL_COUNTRIES, SelectedCountryContextProvider, useQueryParam } from '@modules';
import { CountryDropdownList } from '@types';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import LoadingPacman from '../shared/components/base/LoadingPacman';
import { HistoryRankingChart } from './HistoryRankingChart';

export function HistoryRankingChartWrapper() {
  const countries = useStaticQuery<CountryDropdownList>(graphql`
    query {
      allCountry(filter: { language: { eq: "en" } }) {
        edges {
          node {
            id
            name
            flag {
              alternativeText
              url
            }
            countryCode
          }
        }
      }
    }
  `);

  const [countryCode] = useQueryParam('countryCode');
  const defaultCountry = React.useMemo(
    () => countries.allCountry.edges?.find((c) => c.node.countryCode === (countryCode || ALL_COUNTRIES)),
    [countries, countryCode]
  )?.node;

  const processedCountries = countries.allCountry.edges.map((c) => c.node);

  if (!defaultCountry) {
    return <LoadingPacman />;
  }

  return (
    <SelectedCountryContextProvider defaultValue={defaultCountry}>
      <HistoryRankingChart countries={processedCountries} />
    </SelectedCountryContextProvider>
  );
}
