import { SectionWithContent } from '@core';
import { Grid } from '@themed';
import { CountryDropdown } from '@types';
import React from 'react';
import { CountrySelector } from '../../finnoscore-list/CountrySelector';
import { ChartDropDownHeader, ChartWizardStep, finnoChartStyles } from '../shared/components/base';
import { ChartDropDownControlComponent } from '../shared/components/control';
import { usePeriodDropDownControl, useTypeDropDownControl } from '../shared/hooks';
import { DimensionSelectorWithContext } from './components/DimensionDropdown';
import { DimensionOrCountrySwitch } from './components/DimensionOrCountrySwitch';
import { HistoryRankingTable } from './components/RankingTable';
import { useHistoryRankingData } from './hooks/useHistoryRanking';
import { useDimensionKey } from './store/DimensionStore';

const contentStyles = {
  ...finnoChartStyles.content,
  gridTemplateRows: ['max-content max-content auto 1fr auto auto'],
};

type HistoryRankingChartProps = {
  countries: CountryDropdown[];
};

export const HistoryRankingChart = ({ countries }: HistoryRankingChartProps) => {
  const { types, setTypeId, typeId, type: fsType, isLoading: isTypeLoading } = useTypeDropDownControl();
  const {
    periods: relativePeriodList,
    period: relativePeriod,
    periodId: relativePeriodId,
    setPeriodId: setRelativePeriodId,
    isLoading: isRelativePeriodLoading,
  } = usePeriodDropDownControl({ type: fsType });

  const {
    periods: currentPeriodList,
    period: currentPeriod,
    periodId: currentPeriodId,
    setPeriodId: setcurrentPeriodId,
  } = usePeriodDropDownControl({ type: fsType });

  const { data: historyRanking, isLoading } = useHistoryRankingData(fsType?.text, currentPeriod?.date, relativePeriod?.date);

  const [rankedDimension] = useDimensionKey();
  const dimensions = historyRanking?.[0]?.current.dimensions;

  const shouldShowRanking = fsType?.text && currentPeriod?.date && relativePeriod?.date;

  return (
    <SectionWithContent sx={finnoChartStyles.container}>
      <Grid sx={contentStyles}>
        <ChartWizardStep id='type' label='Type' isLoading={isTypeLoading}>
          <ChartDropDownControlComponent zIndex={20} list={types} selectedId={typeId} setter={setTypeId}>
            <ChartDropDownHeader title={fsType?.text || 'Choose a type'} />
          </ChartDropDownControlComponent>
        </ChartWizardStep>

        {/* The current period, but the ID is pastPeriod—just to position it first in the order. */}
        {fsType && (
          <ChartWizardStep id='pastPeriod' label='Current period' isLoading={isRelativePeriodLoading} loading={null}>
            <ChartDropDownControlComponent zIndex={10} list={currentPeriodList} selectedId={currentPeriodId} setter={setcurrentPeriodId}>
              <ChartDropDownHeader title={currentPeriod?.text || 'Choose a period'}></ChartDropDownHeader>
            </ChartDropDownControlComponent>
          </ChartWizardStep>
        )}

        {fsType && (
          <ChartWizardStep id='currentPeriod' label='Relative period' isLoading={isRelativePeriodLoading}>
            <ChartDropDownControlComponent zIndex={15} list={relativePeriodList} selectedId={relativePeriodId} setter={setRelativePeriodId}>
              <ChartDropDownHeader title={relativePeriod?.text || 'Choose a period'}></ChartDropDownHeader>
            </ChartDropDownControlComponent>
          </ChartWizardStep>
        )}

        {shouldShowRanking && (
          <ChartWizardStep id='bankSelector' label='Country filter' isLoading={isLoading}>
            {dimensions?.[0] && <DimensionOrCountrySwitch firstDimensionKey={dimensions?.[0]?.key} countries={countries} />}

            {rankedDimension && dimensions ? (
              <DimensionSelectorWithContext dimensions={dimensions} />
            ) : (
              <CountrySelector countries={countries} sx={{ width: '100% !important' }} />
            )}
          </ChartWizardStep>
        )}

        {shouldShowRanking && (
          <ChartWizardStep id='chart' label='Ranking Table' isLoading={isLoading}>
            <HistoryRankingTable data={historyRanking} fsType={fsType!} date={currentPeriod!.date} compareDate={relativePeriod!.date} />
          </ChartWizardStep>
        )}
      </Grid>
    </SectionWithContent>
  );
};
