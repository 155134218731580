import { useMemo } from 'react';
import { HistoryRanking } from '../hooks';
import { useDimensionKey } from '../store/DimensionStore';

export function useDimensionFilter(data: HistoryRanking[]) {
  const [dimensionKey] = useDimensionKey();

  return useMemo(() => {
    if (dimensionKey === null) {
      return data;
    }

    // Create dimension maps for faster lookup
    const rankedByDimension = data.map((item) => {
      // Create maps of dimensions for current and compared
      const currentDimensionsMap = new Map(item.current.dimensions?.map((d) => [d.key, d]) || []);
      const comparedDimensionsMap = new Map(item.compared.dimensions?.map((d) => [d.key, d]) || []);

      const currentDimension = currentDimensionsMap.get(dimensionKey);
      const comparedDimension = comparedDimensionsMap.get(dimensionKey);

      const currentDimensionValue = Number(currentDimension?.value ?? 0);
      const comparedDimensionValue = Number(comparedDimension?.value ?? 0);

      const currentDimensionRank = currentDimension?.rank ?? 0;
      const comparedDimensionRank = comparedDimension?.rank ?? 0;

      return {
        ...item,
        current: {
          ...item.current,
          rank: currentDimensionRank,
          total: currentDimensionValue,
          totalTrend: comparedDimensionValue ? currentDimensionValue - comparedDimensionValue : undefined,
          rankTrend: comparedDimensionValue ? comparedDimensionRank - currentDimensionRank : undefined,
        },
        compared: {
          ...item.compared,
          rank: comparedDimensionRank,
          total: comparedDimensionValue,
        },
      };
    });

    return rankedByDimension.sort((a, b) => a.current.rank - b.current.rank);
  }, [data, dimensionKey]);
}
