import { ALL_COUNTRIES, useSelectedCountry } from '@modules';
import { HistoryRanking } from './useHistoryRanking';

export function useIsCountryRanking() {
  const selectedCountry = useSelectedCountry();
  return selectedCountry.countryCode !== ALL_COUNTRIES;
}

export function useCountryFilter(ranking: HistoryRanking[]) {
  const selectedCountry = useSelectedCountry();

  if (selectedCountry.countryCode === ALL_COUNTRIES) {
    return ranking;
  }

  return ranking.filter((r) => r.current.country?.countryCode === selectedCountry.countryCode);
}
