import { ALL_COUNTRIES, SelectedCountryContext } from '@modules';
import { Country, DimensionKey } from '@types';
import React from 'react';
import { LegacySwitch } from '../../comparison/components/LegacySwitch';
import { useDimensionKey } from '../store/DimensionStore';

const dimensionSwitchTitles = [
  {
    title: 'Country',
  },
  {
    title: 'Dimension',
  },
];

type DimensionOrCountrySwitchProps = {
  firstDimensionKey: DimensionKey;
  countries: Country[];
};

export function DimensionOrCountrySwitch({ firstDimensionKey, countries }: DimensionOrCountrySwitchProps) {
  const [selectedDimensionKey, setSelectedDimensionKey] = useDimensionKey();
  const { setCountry } = React.useContext(SelectedCountryContext);

  function onSwitchChange(value: number) {
    setSelectedDimensionKey(value === 1 ? firstDimensionKey : null);

    /**
     * Reset the selected country to the all countries if the dimension is selected.
     */
    if (value === 1) {
      setCountry(countries.find((c) => c.countryCode === ALL_COUNTRIES) ?? countries[0]);
    }
  }

  return <LegacySwitch items={dimensionSwitchTitles} value={selectedDimensionKey ? 1 : 0} changeHandler={onSwitchChange} />;
}
