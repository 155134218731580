import { Link } from '@core';
import styled from '@emotion/styled';
import { Flex, Image, SxStyleProp, Text, themeConfig } from '@themed';
import React, { memo } from 'react';
import { TrendComponent } from 'src/components/composite/finnoscore-list/Trend';
import { HistoryRanking } from '../hooks';
import { getTrend } from '../utils/trend';
import { styles } from './RankingTable.styles';

const StyledCellText = styled(Text)({
  ...styles.tbodyText,
});

const TableRowContainer = styled.tr({
  borderBottom: `1px solid ${themeConfig.colors.mutedDark}`,
  '&:hover': {
    backgroundColor: themeConfig.colors.primaryLighter[5],
    cursor: 'pointer',
  },
});

type LinkedTableCellProps = {
  children: React.ReactNode;
  linkProps: {
    to: string;
    sx: SxStyleProp;
  };
};

const LinkedTableCell = memo(({ children, linkProps }: LinkedTableCellProps) => (
  <td>
    <Link to={linkProps.to} sx={linkProps.sx}>
      {children}
    </Link>
  </td>
));

type TableRowProps = {
  ranking: HistoryRanking;
  linkProps: {
    to: string;
    sx: SxStyleProp;
  };
};

export const RankingTableRow = memo(({ ranking, linkProps }: TableRowProps) => {
  const { current, compared } = ranking;
  return (
    <TableRowContainer>
      <LinkedTableCell linkProps={linkProps}>
        <StyledCellText>{current.rank}</StyledCellText>
      </LinkedTableCell>

      <LinkedTableCell linkProps={linkProps}>
        <Link to={linkProps.to} sx={linkProps.sx}>
          <Flex sx={{ alignItems: 'center' }}>
            {current?.logo ? <Image src={current.logo?.url} alt={current.logo?.alternativeText} sx={styles.logo} /> : <span>&nbsp;</span>}
            <Text sx={styles.name}>{current.name}</Text>
          </Flex>
        </Link>
      </LinkedTableCell>

      <LinkedTableCell linkProps={linkProps}>
        <StyledCellText sx={{ fontSize: '0.875rem' }}>{current.country?.name}</StyledCellText>
      </LinkedTableCell>

      <LinkedTableCell linkProps={linkProps}>
        <Flex variant='flex.center'>
          <StyledCellText>{current.total.toFixed(2)}</StyledCellText>
          <TrendComponent sx={styles.trendIcon} trend={getTrend(current.totalTrend)} />
        </Flex>
      </LinkedTableCell>

      <LinkedTableCell linkProps={linkProps}>
        <StyledCellText>{compared.total !== 0 ? compared.total?.toFixed(2) : '-'}</StyledCellText>
      </LinkedTableCell>

      <LinkedTableCell linkProps={linkProps}>
        <StyledCellText sx={styles.trend(current.totalTrend)}>{current.totalTrend?.toFixed(2)}</StyledCellText>
      </LinkedTableCell>

      <LinkedTableCell linkProps={linkProps}>
        <Flex variant='flex.center'>
          <StyledCellText>{current.rank}</StyledCellText>
          <TrendComponent sx={styles.trendIcon} trend={getTrend(current.rankTrend)} />
        </Flex>
      </LinkedTableCell>

      <LinkedTableCell linkProps={linkProps}>
        <StyledCellText>{compared.rank}</StyledCellText>
      </LinkedTableCell>

      <LinkedTableCell linkProps={linkProps}>
        <StyledCellText sx={styles.trend(current.rankTrend)}>{current.rankTrend}</StyledCellText>
      </LinkedTableCell>
    </TableRowContainer>
  );
});
RankingTableRow.displayName = 'RankingTableRow';

export const CountryRankingTableRow = memo(({ ranking, linkProps }: TableRowProps) => {
  const { current, compared } = ranking;

  return (
    <TableRowContainer>
      <LinkedTableCell linkProps={linkProps}>
        <StyledCellText>{current.countryRank}</StyledCellText>
      </LinkedTableCell>

      <LinkedTableCell linkProps={linkProps}>
        <Link to={linkProps.to} sx={linkProps.sx}>
          <Flex sx={{ alignItems: 'center' }}>
            {current?.logo ? <Image src={current.logo?.url} alt={current.logo?.alternativeText} sx={styles.logo} /> : <span>&nbsp;</span>}
            <Text sx={styles.name}>{current.name}</Text>
          </Flex>
        </Link>
      </LinkedTableCell>

      <LinkedTableCell linkProps={linkProps}>
        <StyledCellText sx={{ fontSize: '0.875rem' }}>{current.country?.name}</StyledCellText>
      </LinkedTableCell>

      <LinkedTableCell linkProps={linkProps}>
        <Flex variant='flex.center'>
          <StyledCellText>{current.total.toFixed(2)}</StyledCellText>
          <TrendComponent sx={styles.trendIcon} trend={getTrend(current.totalTrend)} />
        </Flex>
      </LinkedTableCell>

      <LinkedTableCell linkProps={linkProps}>
        <StyledCellText>{compared.total !== 0 ? compared.total?.toFixed(2) : '-'}</StyledCellText>
      </LinkedTableCell>

      <LinkedTableCell linkProps={linkProps}>
        <StyledCellText sx={styles.trend(current.totalTrend)}>{current.totalTrend?.toFixed(2)}</StyledCellText>
      </LinkedTableCell>

      <LinkedTableCell linkProps={linkProps}>
        <Flex variant='flex.center'>
          <StyledCellText>{current.countryRank}</StyledCellText>
          <TrendComponent sx={styles.trendIcon} trend={getTrend(current.countryRankTrend)} />
        </Flex>
      </LinkedTableCell>

      <LinkedTableCell linkProps={linkProps}>
        <StyledCellText>{compared.countryRank}</StyledCellText>
      </LinkedTableCell>

      <LinkedTableCell linkProps={linkProps}>
        <StyledCellText sx={styles.trend(current.countryRankTrend)}>{current.countryRankTrend}</StyledCellText>
      </LinkedTableCell>
    </TableRowContainer>
  );
});
CountryRankingTableRow.displayName = 'CountryRankingTableRow';
