import { themeConfig } from '@themed';
import { SxStyleProp } from 'theme-ui';

const headerHeight = parseInt(themeConfig.sizes.headerHeight, 10);
const footerHeight = parseInt(themeConfig.sizes.footerHeight, 10);

export const styles = {
  table: {
    height: '100%',
    maxHeight: `calc(100vh - ${headerHeight}px - ${footerHeight}px - 18vh)`,
    overflow: 'auto',
    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 6px 0px',
  } as SxStyleProp,

  theadText: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontSize: '0.875rem',
    color: 'grey.1',
    padding: '1rem',
    textAlign: 'left',
  } as SxStyleProp,

  tbodyText: {
    fontSize: 'xxs',
    paddingLeft: '1rem',
    paddingRight: '1rem',
    paddingTop: '0.7rem',
    paddingBottom: '0.7rem',
  },

  trend: (trend?: number) => ({
    color: trend && trend > 0 ? 'primary' : 'warning',
  }),

  trendIcon: {
    bg: 'transparent',
    transform: 'scaleX(-1)',
  },

  name: { ml: 4, fontWeight: 'bold' },

  logo: {
    width: 6,
    textAlign: 'left',
    mt: [1, 1, 0],
    fontSize: '1px',
  } as SxStyleProp,
};
