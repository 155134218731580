import React from 'react';
import { ProtectedRouteAndRedirect } from 'src/components/composite/ProtectedRoute';
import { HistoryRankingChartWrapper } from 'src/components/composite/chart-generator/history-ranking';
import { ChartContextWrapper } from 'src/components/composite/chart-generator/shared';

export default function () {
  return (
    <ChartContextWrapper>
      <ProtectedRouteAndRedirect path='/history-ranking-chart' component={HistoryRankingChartWrapper} />
    </ChartContextWrapper>
  );
}
