import { DefaultDropDownHeader, DropDownContext, DropDownContextProvider, DropDownWithContext, SearchList } from '@core';
import { Dimension, DimensionKey } from '@types';
import React from 'react';
import { CountryListItem } from 'src/components/composite/finnoscore-data/List';
import { useDimensionKey } from '../store/DimensionStore';

function convertDimensionsToListItem(dimensions: Dimension[]) {
  return dimensions.map((d) => ({
    id: d.key,
    text: d.key,
  }));
}

type DimensionSelectorProps = {
  dimensions: Dimension[];
};

const styles = {
  dropDown: {
    width: '100%',
    height: 'max-content',
    boxShadow: ['none', 'none', '0 0 6px 0 rgba(0, 0, 0, 0.1)'],
    zIndex: 1,
  },
};

const DimensionSelector = ({ dimensions }: DimensionSelectorProps) => {
  const [selectedDimensionKey, setSelectedDimensionKey] = useDimensionKey();
  const { setIsOpen } = React.useContext(DropDownContext);

  const dimensionListItems = convertDimensionsToListItem(dimensions);

  function onListItemSelected(dimensionKey: string) {
    setSelectedDimensionKey(dimensionKey as DimensionKey);
    setIsOpen(false);
  }

  if (!selectedDimensionKey) {
    console.warn('selectedDimensionKey is not set');
    return null;
  }

  return (
    <DropDownWithContext sx={styles.dropDown} header={<DefaultDropDownHeader title={selectedDimensionKey} />}>
      <SearchList listItems={dimensionListItems} onSelect={onListItemSelected} selected={selectedDimensionKey} ListItemComponent={CountryListItem} />
    </DropDownWithContext>
  );
};

export const DimensionSelectorWithContext = (props: DimensionSelectorProps) => {
  return (
    <DropDownContextProvider>
      <DimensionSelector {...props} />
    </DropDownContextProvider>
  );
};
