import styled from '@emotion/styled';
import { __, useSelectedCountry } from '@modules';
import { themeConfig } from '@themed';
import { FsType, FsTypeBase } from '@types';
import React, { memo } from 'react';
import { Text } from 'theme-ui';
import { styles } from './RankingTable.styles';

const Thead = styled.thead({
  borderBottom: `1px solid ${themeConfig.colors.mutedDark}`,
  position: 'sticky',
  top: 0,
  background: 'white',
  zIndex: 1,
});

const TableHeaderText = memo(({ children }: { children: React.ReactNode }) => (
  <th>
    <Text sx={styles.theadText}>{children}</Text>
  </th>
));
TableHeaderText.displayName = 'TableHeaderText';

const TableHeaderTextWithDate = memo(({ children, date }: { children: React.ReactNode; date: string }) => (
  <TableHeaderText>
    {children}
    <Text sx={{ fontSize: 'xxxs' }}>({date})</Text>
  </TableHeaderText>
));
TableHeaderTextWithDate.displayName = 'TableHeaderTextWithDate';

export const RankingTableHeader = memo(({ fsType, date, compareDate }: { fsType: FsTypeBase<FsType>; date: string; compareDate: string }) => {
  return (
    <Thead>
      <tr>
        <TableHeaderText>{__('finnoscore.list.rank')}</TableHeaderText>
        <TableHeaderText>{__(`finnoscore.list.${fsType.text}`)}</TableHeaderText>
        <TableHeaderText>{__('finnoscore.list.country')}</TableHeaderText>
        <TableHeaderTextWithDate date={date}>{__('common.score')}</TableHeaderTextWithDate>
        <TableHeaderTextWithDate date={compareDate}>{__('common.score')}</TableHeaderTextWithDate>
        <TableHeaderText>{__('common.scoreDifference')}</TableHeaderText>
        <TableHeaderTextWithDate date={date}>{__('finnoscore.list.rank')}</TableHeaderTextWithDate>
        <TableHeaderTextWithDate date={compareDate}>{__('finnoscore.list.rank')}</TableHeaderTextWithDate>
        <TableHeaderText>{__('common.rankDifference')}</TableHeaderText>
      </tr>
    </Thead>
  );
});
RankingTableHeader.displayName = 'RankingTableHeader';

export const CountryRankingTableHeader = memo(({ fsType, date, compareDate }: { fsType: FsTypeBase<FsType>; date: string; compareDate: string }) => {
  const selectedCountry = useSelectedCountry();
  const rankTitle = `${selectedCountry.countryCode} ${__('finnoscore.list.rank')}`;

  return (
    <Thead>
      <tr>
        <TableHeaderText>{__('finnoscore.list.rank')}</TableHeaderText>
        <TableHeaderText>{__(`finnoscore.list.${fsType.text}`)}</TableHeaderText>
        <TableHeaderText>{__('finnoscore.list.country')}</TableHeaderText>
        <TableHeaderTextWithDate date={date}>{__('common.score')}</TableHeaderTextWithDate>
        <TableHeaderTextWithDate date={compareDate}>{__('common.score')}</TableHeaderTextWithDate>
        <TableHeaderText>{__('common.scoreDifference')}</TableHeaderText>
        <TableHeaderTextWithDate date={date}>{rankTitle}</TableHeaderTextWithDate>
        <TableHeaderTextWithDate date={compareDate}>{rankTitle}</TableHeaderTextWithDate>
        <TableHeaderText>{__('common.rankDifference')}</TableHeaderText>
      </tr>
    </Thead>
  );
});
CountryRankingTableHeader.displayName = 'CountryRankingTableHeader';
